import "./App.scss";
import debounce from "lodash.debounce";
import { Component } from "inferno";
import { getResults } from "./utils";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { results: [] };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ results: getResults(event.target.value) });
  }

  render() {
    return (
      <div className="box">
        <input
          onInput={debounce(this.handleChange, 500)}
          className="input"
          type="text"
          placeholder="Place or phone number"
        />
        {!!(this.state.results && this.state.results.length) &&
          (this.state.results.length > 1 ? (
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Dest</th>
                  <th>Credit(s)</th>
                </tr>
              </thead>
              <tbody>
                {this.state.results.map((object, i) => (
                  <tr>
                    <td>{object["#id"]}</td>
                    <td>{object["CSGRates Retail"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Dest</th>
                  <th>Credit(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.results[0]["#id"]}</td>
                  <td>{this.state.results[0]["CSGRates Retail"]}</td>
                </tr>
              </tbody>
            </table>
          ))}
      </div>
    );
  }
}

export default App;
