import loki from "lokijs";
const dests = JSON.parse(Get("Destinations.json"));
const destRates = JSON.parse(Get("DestinationsRates.json"));
const db = new loki("rates");

const destinations = db.addCollection("destinations");
destinations.insert(dests);

const destinationRates = db.addCollection("destinationRates");
destinationRates.insert(destRates);

export function Get(yourUrl) {
  var Httpreq = new XMLHttpRequest(); // a new request
  Httpreq.open("GET", yourUrl, false);
  Httpreq.send(null);
  return Httpreq.responseText;
}


export function getResults(string) {
  console.log(string);
  let results = [];

  // phone number
  if(parseInt(string)) {
    // we need to through all possible prefix until we find one
    let count = 0;
    while(count < string.length) {
      console.log('inside loop ', count, string.substr(0,string.length - count));
      const stringToRegex = string.substr(0, string.length - count);
      const regex = new RegExp("\\+" + stringToRegex + "*$");
      const foundDest = destinations.findOne({'Prefix': { '$regex' : regex}});

      console.log(foundDest);
    
      if(foundDest) {
        const foundRate = destinationRates.findOne({'#id': { '$eq': foundDest["#id"] }});
        if(foundRate) {
          console.log(foundRate);
          count = false;
          return [foundRate];
        }
      }
      count++;
    }
    return [];
  }

  // country
  if(typeof string === "string" && string.length !== 0) {
    console.log(string, string.length);
    const regex = new RegExp(string, "i");
    const foundRates = destinationRates.find({'#id': {'$regex': regex }});
    if(foundRates) {
      return foundRates;
    }
    return [];
  }



  console.log(
    destinations.findOne({'Prefix': { '$regex' : /\+1778/ }})
  )

  return []
}
